/* --- custom-green-btn start --- */
.custom-green-btn{
    background-color: #082a2b !important;
    color: white !important;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.custom-green-btn:hover{
    background-color: rgb(24 126 129) !important;
    color: white !important;
}
/* --- custom-green-btn end --- */


/* --- custom-slider-btn strat --- */
.custom-slider-btn:hover{
    color:  #a13143;
    border-color:  #a13143 !important;
}
/* --- custom-slider-btn end --- */

/* --- cart-add-btn start --- */
.cart-add-btn{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cart-add-btn.cab-max:hover{
    color: white;
    border-color: #22c55e !important;
    background-color: #22c55e !important;
}

.cart-add-btn.cab-min:hover{
    color: white;
    border-color: #ff431d !important;
    background-color: #ff431d !important;
}
/* --- cart-add-btn end --- */

/* --- cart-remove-btn start --- */
.cart-remove-btn{
    color:  #a13143;
    padding: 3px 6px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cart-remove-btn:hover{
    color:  #a13143;
    background-color: #fa826a4d;
}
/* --- cart-remove-btn end --- */






/* .header-top-social .lab, */
.header-top-conatct svg{
    color:  #a13143;
}

.custom-nc-Navitem button,
.header-menu-item{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.custom-nc-Navitem button:hover,
.header-menu-item:hover{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #000 !important;
}


.main-slider-bx .main-slider-btn{
    margin-top: 30px !important;
}


/* --- Main Slider --- */
.main-slider{
    max-width: 1920px;
    width: 100%;
    margin: auto;
    margin-bottom: -8px;
}


/* --- logo Animation start --- */
.icon-r-bx {
    -webkit-perspective: 400px;
    -moz-perspective: 400px;
         perspective: 400px;
         pointer-events: none;
}

.icon-r-ani {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
         transform-style: preserve-3d;
    position: relative;
    -webkit-animation: animaa 8s 8s infinite;
    -moz-animation: animaa 8s 8s infinite;
      -o-animation: animaa 8s 8s infinite;
         animation: animaa 8s 8s infinite;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    -moz-transform-origin: center;
      -o-transform-origin: center;
         transform-origin: center;
}

.icon-r-ani .icons-f,
.icon-r-ani .icons-b {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
         backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.icon-r-ani .icons-f {
    background-image: url('./images/logo/favicon.png');
    background-position: center;
    -webkit-background-size: contain;
       -moz-background-size: contain;
         -o-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
}

.icon-r-ani .icons-b {
    background-image: url('./images/logo/favicon.png');
    /* background-image: url('./images/logo/favicon-bg.png'); */
    background-position: center;
    -webkit-background-size: contain;
       -moz-background-size: contain;
         -o-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat;
    -webkit-transform: rotatey(180deg);
    -ms-transform: rotatey(180deg);
    -moz-transform: rotatey(180deg);
      -o-transform: rotatey(180deg);
         transform: rotatey(180deg);
}

.icon-r-bx {
    position: absolute;
    left: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    background: white;
    z-index: 1;
}

.icon-r-bx,
.icon-r-bx .icon-r-ani,
.icon-r-bx .icon-r-ani .icons-f,
.icon-r-bx .icon-r-ani .icons-b {
    width: 76px;
    height: 76px;
}

@-webkit-keyframes animaa {

    0%,
    5% {
        -webkit-transform: rotatey(0deg);
        transform: rotatey(0deg);
    }

    45%,
    50% {
        -webkit-transform: rotatey(180deg);
        transform: rotatey(180deg);
    }

    100% {
        -webkit-transform: rotatey(360deg);
        transform: rotatey(360deg);
    }

}

@-moz-keyframes animaa {

    0%,
    5% {
        -webkit-transform: rotatey(0deg);
        -moz-transform: rotatey(0deg);
             transform: rotatey(0deg);
    }

    45%,
    50% {
        -webkit-transform: rotatey(180deg);
        -moz-transform: rotatey(180deg);
             transform: rotatey(180deg);
    }

    100% {
        -webkit-transform: rotatey(360deg);
        -moz-transform: rotatey(360deg);
             transform: rotatey(360deg);
    }

}

@-o-keyframes animaa {

    0%,
    5% {
        -webkit-transform: rotatey(0deg);
        -o-transform: rotatey(0deg);
           transform: rotatey(0deg);
    }

    45%,
    50% {
        -webkit-transform: rotatey(180deg);
        -o-transform: rotatey(180deg);
           transform: rotatey(180deg);
    }

    100% {
        -webkit-transform: rotatey(360deg);
        -o-transform: rotatey(360deg);
           transform: rotatey(360deg);
    }

}

@keyframes animaa {

    0%,
    5% {
        -webkit-transform: rotatey(0deg);
        -moz-transform: rotatey(0deg);
          -o-transform: rotatey(0deg);
             transform: rotatey(0deg);
    }

    45%,
    50% {
        -webkit-transform: rotatey(180deg);
        -moz-transform: rotatey(180deg);
          -o-transform: rotatey(180deg);
             transform: rotatey(180deg);
    }

    100% {
        -webkit-transform: rotatey(360deg);
        -moz-transform: rotatey(360deg);
          -o-transform: rotatey(360deg);
             transform: rotatey(360deg);
    }

}
/* --- logo Animation end --- */

@media screen and (min-width:0px) {
    .sidemenu-logo img,
    .footer-logo img,
    .header-logo img
    {
        width: 210px;
        height: auto;
        max-height: inherit;
        padding: 10px;
    }

    .header-logo{
        position: relative;
    }

    .anim-logo-bx{
        left: 0;
        top: 10px;
        bottom: inherit;
    }

    .footer-logo img{
       max-width: 300px !important;
    }
}

@media screen and (max-width:380px) {
    .hl-outer-bx{
        transform: scale(0.85);
    }
}


/* @media screen and (min-width:1024px) {
    .header-logo img{
        position: absolute;
        width: 150px;
        height: auto;
        max-height: inherit;
        padding: 10px;
        left: 0;
        bottom: 8px;
    }

    .header-logo{
        position: static;
    }

    .header-logo .anim-logo-bx {
        left: 0;
        top: inherit;
        bottom: 71px;
    }

} */


.footer-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px 0;
    margin-top: 10px
}

.copyright {
    font-size: 14px
}

.mede-with {
    font-size: 14px;
    margin: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.mede-with a {
    font-weight: 500;
    opacity: .8
}

.mede-with:hover a,
.mede-with a:hover {
    opacity: 1;
    color: #fa826a;
}

.icons-box-made {
    width: 18px;
    height: 18px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    top: 7px;
    margin: 0 2px;
}

/* .icons-box-made .fa {
    position: absolute
}

.mede-with .fa:nth-child(1) {
    font-size: 12px;
    transition: all .3s;
    opacity: 1
} */

/* .mede-with .fa:nth-child(2) {
    font-size: 12px;
    transition: all .3s;
    opacity: 0;
    transform: scale(0)
} */

/* .mede-with:hover .fa:nth-child(1) {
    opacity: 0
} */

.mede-with:hover svg {
    opacity: 1;
    fill: #fa826a;
    color: #fa826a;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}



@media screen and (max-width: 768px) {
    .footer-bottom {
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
            -ms-flex-direction:column;
                flex-direction:column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
    }

    .copyright {
        margin-bottom: 10px
    }
}

@media screen and (max-width: 435px) {
    .header-top-conatct a:last-child{
        display: none;
    }
}


/* --- Product Slider css start --- */
.thumbnail-slider .slick-active .thumbnail-item{
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
    border: 2px solid rgb(226 232 240);
}
.thumbnail-slider .slick-active .thumbnail-item:hover{
    opacity: 1;
    /* border: 2px solid #d2af6d; */
    border: 2px solid #a13143;
}

.thumbnail-slider .slick-current .thumbnail-item{
    opacity: 1;
    /* border-color:#d2af6d; */
    border-color:#a13143
}
/* --- Product Slider css end --- */


/* --- Skeletant Design css start --- */
.skeletant-bx {
    pointer-events: none;
}

.skeletant-design {
    background-color: rgb(243, 244, 246);
    background-image: url("./images/placeholder-small4.png");
    /* background-image: url("./images/logo/icon-gray.png"),  linear-gradient(90deg, #192436 40%, #111827 50%, #192436 60%); */
    background-position:center;
    background-repeat:repeat;
    background-size:400px;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: shine-lines 5s infinite linear;
    pointer-events: none;
    /* box-shadow: 0 0 20px -10px #c4c4c4; */
    border-radius: 16px;
}

.skeletant-design.ld-no-img{
    background-size:0;
    animation: shine-lines 0.3s infinite linear;
}

.skeletant-design.ld-no-radius{
    border-radius: 0;
}

@-webkit-keyframes shine-lines {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.7;
    }
    
    100%{
        opacity: 1;
    }
}

@keyframes shine-lines {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.7;
    }

    100%{
        opacity: 1;
    }
}
/* --- Skeletant Design css end --- */


/* --- Silke slider btn css start --- */
.slick-arrow{
    width: 40px;
    height: 40px;
    border: 2px solid #d6d6d6;
    background-color: transparent;
    border-radius: 50%;
    font-size: 24px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
    opacity: 0.9;
    margin: 0;
    padding: 5px;
    overflow: hidden;
    color: transparent;
}

.slick-arrow.slick-prev:before, 
.slick-arrow.slick-next:before{
    content: none;
}

.slick-arrow::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 22px 22px;
    transition: all 0.3s ease-in-out;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
    position: absolute;
    transform: translateY(-50%);
    z-index: 5;
}

.slick-arrow.slick-prev {
    left: 15px;
    top: 50%;
}

.slick-arrow.slick-prev::after {
    background-position: center center;
    background-image: url("/src/images/icons/chevron-back-outline.svg");
}

.slick-arrow.slick-next{
    right: 15px;
    top: 50%;

}

.slick-arrow.slick-next::after {
    background-position: center center;
    background-image: url("/src/images/icons/chevron-forward-outline.svg");
}


.slick-arrow:hover{
    border-color:  #a13143;
    background-color:  #a13143;
    color: transparent;
}

.nav-warro-white .slick-arrow.slick-next::after,
/* .nav-warro-white .slick-arrow.slick-next:hover::after, */
.slick-arrow.slick-next:hover::after {
    background-image: url("/src/images/icons/chevron-forward-outline-white.svg");
}

.nav-warro-white .slick-arrow.slick-prev::after,
/* .nav-warro-white .slick-arrow.slick-prev:hover::after, */
.slick-arrow.slick-prev:hover::after {
    background-image: url("/src/images/icons/chevron-back-outline-white.svg");
}

.slick-disabled{
    opacity: 0.5;
    cursor: inherit;
}

/* --- Silke slider btn css end --- */

/* --- Lightgallery start --- */

.lg-counter{
    color: white !important;
}

.lg-toolbar .lg-icon{
    color: rgba(255, 255, 255, 0.7) !important;
}

.lg-toolbar .lg-icon:hover{
    color: #ffffff !important;
}

.lg-download.lg-icon{
    display: none !important;
}

.lg-backdrop{
    background-color: #0000004d !important;
    backdrop-filter: blur(5px) !important;
}

.lg-prev,
.lg-next{
    width: 46px !important;
    height: 46px !important;
    color:  #a13143 !important;
    border: 2px solid  #a13143 !important;
    border-radius: 50px !important;
    background-color: transparent !important;
    transition: all 0.3s ease-in-out !important;
}

.lg-prev:hover,
.lg-next:hover{
    color: white !important;
    background-color:  #a13143 !important;
}

.lg-img-wrap{
    padding: 20px !important;
    padding-top: 0 !important;
}
/* --- Lightgallery end --- */


/* --- Lagal pages css start --- */
.lagle-container{
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0 15px;
}

.lagle-heading{
    color:  #a13143;
    font-size: 32px;
    margin-bottom: 35px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
}

.legal-content .main-lable {
    margin-top: 30px;
    margin-bottom: -13px;
    font-weight: 500;
    color: black;
    font-size: 24px;
    text-transform: capitalize;
    line-height: normal;
}

.legal-content .main-sub-lable {
    margin-top: 30px;
    font-weight: 500;
    color: rgb(55, 65, 81);
    font-size: 22px;
    text-transform: capitalize;
    margin-bottom: -10px;
    line-height: normal;
}

.legal-ul li,
.legal-content p {
    font-size: 16px;
    line-height: 1.7;
    margin: 0;
    margin-top: 15px;
    color: rgb(75, 85, 99);
    font-weight: 400;
}

.legal-ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin-left: 15px;
    /* list-style-position: inside; */
}

.legal-ul.ul-dick {
    list-style-type: none;
}

.legal-ul li a,
.legal-content p a {
    text-decoration: underline;
}

.legal-ul li a:hover,
.legal-content p a:hover {
    color:  #a13143;
}


@media screen and (max-width:992px) {
    .lagle-heading{
        font-size: 24px;
    }

    .legal-content .main-lable {
        font-size: 20px;
        line-height: normal;
    }

    .legal-content .main-sub-lable {
        font-size: 18px;
        line-height: normal;
    }
}

/* --- Lagal pages css end --- */

/* --- Sitemap Page Css --- */
.sitemaplinks-bx {
    padding: 10px 10px 10px 10px;
    position: relative;
    margin-bottom: 40px;
}

.sitemap-heding {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    color: rgb(55, 65, 81);
    display: block;
    padding-bottom: 10px;
}

.sitemap-heding::before,
.sitemap-heding::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 20px;
    height: 1px;
    border-radius: 5px;
    background-color: rgb(55, 65, 81);
}

.sitemap-heding::before {
    left: inherit;
    right: 0;
    top: 100%;
    width: calc(100% - 30px);
    background-color: rgb(55, 65, 81);
}

.sitemap-ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sitemap-ul li {
    width: 19%;
    display: inline-block;
    margin: 4px 0;
}

.sitemap-ul li .sitemap-link {
    cursor: pointer;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
    color: rgb(75, 85, 99);
    font-weight: 400;
}

.sitemap-ul li .sitemap-link svg {
    margin-right: 3px;
    width: 16px;
    height: 16px;
    object-fit: contain;
    object-position: center;
    transition: all 0.3s ease-in-out;
}

.sitemap-ul li .sitemap-link:hover {
    color:  #a13143;
    transform: translateX(10px);
}

.sitemap-ul li .sitemap-link:hover svg {
    color:  #a13143;
}

@media screen and (max-width:992px) {
    .sitemap-ul li {
        width: 24%;
    }
}

@media screen and (max-width:768px) {
    .sitemap-ul li {
        width: 32%;
    }
}

@media screen and (max-width:576px) {
    .sitemap-ul li {
        width: 49%;
    }
}
/* --- Sitemap page css end --- */


/* --- Faqs page css --- */
.faqs-accordion{
    background-color: rgb(244 247 250);
    border-radius: 16px;
    margin-top: 20px;
    padding: 10px 20px;
    box-shadow: 0 0 50px -15px rgb(244 247 250);
}

.faqs-accordion .acc-item{
    position: relative;
    padding: 5px 0;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.faqs-accordion .acc-item:last-child {
    border-bottom: none;
}

.faqs-accordion .acc-item .acc-heding{
    color:  #a13143;
    background: transparent;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 15px 0px;
    padding-right: 30px;
}

.faqs-accordion .acc-item .acc-heding.active {
    opacity: 0.7;
    color: rgb(55, 65, 81);
}

.faqs-accordion .acc-item .acc-heding.active:hover {
    opacity: 1;
    color: rgb(55, 65, 81);
}

.faqs-accordion .acc-item .acc-heding span.down-icon {
    position: absolute;
    right: 0px;
    top: 48%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    font-size: 20px;
    border-radius: 50%;
    transition: 0.3s ease;
}

.faqs-accordion .acc-item .acc-heding.active span.down-icon {
    transform: translateY(-50%) rotate(180deg);
}

.faqs-accordion .acc-item .faqs-data{
    color: rgb(75, 85, 99);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 1.6;
}


.faqs-accordion .acc-item .faqs-data a {
    text-decoration: underline;
}

.faqs-accordion .acc-item .faqs-data a:hover {
    color:  #a13143;
}

.faqs-accordion .acc-item .acc-data.active{
    opacity: 0;
    visibility: collapse;
    display: none;
}

.faqs-accordion .acc-item .acc-data{
    opacity: 1;
    visibility: visible;
    display: block;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

}
/* --- Faqs page css end --- */

/* --- custom-drop-bx start --- */
.custome-drop-bx{
    position: relative;
}

.custome-drop-bx .header-menu-item{
    cursor: pointer;
}

.custome-drop-bx:hover .header-menu-item{
    color:  #a13143;
    background-color: rgba(244, 49, 134, 0.1);
}

.custome-drop-bx .custome-drop-item{
    position: absolute;
    top: calc(100% + 0px);
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid rgba(211, 211, 211, 0.616);
    background-color: white;
    padding: 5px 10px;
    width: 220px;
    border-radius: 15px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.custome-drop-bx:hover .custome-drop-item{
    opacity: 1;
    visibility: visible;
}

.custome-drop-bx .custome-drop-item > *{
    display: block;
    padding: 8px 8px;
    border-radius: 5px;
    font-size: 15px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin: 5px 0;
}

.custome-drop-bx .custome-drop-item > *:hover{
    background-color: #d6d6d660;
}

.custome-drop-bx .custome-drop-item > *.active{
    color:  #a13143;
    background-color: rgba(244, 49, 134, 0.1);
}
/* --- custom-drop-bx end --- */


/* --- Header Logo & Login buttons --- */
@media screen and (min-width:1024px) {
    .hlrsbs{
        width: 215px;
        flex: 0 0 215px;
    }
}
/* --- Header Logo & Login buttons --- */


/* =============================
    order page success start
================================ */

.processing {
    padding: 30px 15px;
    background-color: #fdfdfd;
}

.processing .p-main-bx {
    text-align: center;
}

.processing .p-heading{
    color:  #a13143;
    font-size: 28px;
    margin-top: 15px;
    margin-bottom: 25px;
    font-weight: 600;
    text-align: center;
}

.processing .pro-img-bx img {
    width: 160px;
    margin: 10px auto;
    margin-bottom: 25px;
}

.processing .p-order-id {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.processing .p-order-text{
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-top: 5px;
    color: rgb(75, 85, 99);
    font-weight: 500;
}

.processing .p-order-link {
    display: block;
    margin: 25px auto 10px;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid  #a13143;
    outline: none !important;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    color:  #a13143;
    font-size: 16px;
    font-weight: normal;
    padding: 8px 16px;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
}

.processing .p-order-link:hover {
    background-color:  #a13143;
    color: white;
}


/* --- --- */
.PhoneInput{
    position: relative;
}

.PhoneInput input{
    margin-top: 4px;
    padding-left: 60px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(229, 231, 235);
    border-radius: 16px;
}

.PhoneInput input:focus{
    border-color: rgb(125, 211, 252);
   box-shadow: 0 0 0px 3px rgba(2, 133, 199, 0.25);
}

.PhoneInput .PhoneInputCountry{
    position: absolute;
    left: 0;
    top: 4px;
    height: calc(100% - 4px);
    padding: 9px;
    margin: 0;
}

.PhoneInput .PhoneInputCountry::before{
    content: "";
    position: absolute;
    right: -2px;
    top: 9px;
    width: 1px;
    height: calc(100% - 20px);
    background-color: rgb(229, 231, 235);
}

.PhoneInput .PhoneInputCountry select{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}

.PhoneInputCountrySelectArrow{
    position: relative;
    left: 3px;
    top: -2px;
    border-color: white;
    opacity: 1;
}


/* --- product-card-price --- */
.product-card-price > div{
    border: none !important;
    color: black !important;
    padding: 0 !important;
    font-size: 16px;
    margin-top: -5px;
}

.product-card-price > div > span{
    color: #525252 !important;
    margin-right: 5px;
}


.video-main-bx {
    margin: auto;
    max-width: 768px;
    width: 100%;
}

.video-section-bx{
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    padding-top: 56%;
}

.video-section-bx iframe{
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}


/* --- Social Share Icon & Social Icons start --- */
.social-lable{
    display: flex;
    color: #111827;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

.social-icon{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 0;
}

.social-icon .si-item{
    padding: 0px 2px;
    text-align: center;
    cursor: pointer;
}

.social-icon .si-item .si-icon{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 36px;
    height: 36px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    overflow: hidden;
    margin: auto;
    font-size: 16px;
    color: #0F172A;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon::after{
    content: ""; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
       -moz-transform: translate(-50%, -50%) scale(0);
        -ms-transform: translate(-50%, -50%) scale(0);
         -o-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    z-index: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon::before{
    content: ""; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    border: 1px solid #e5e7eb;
    background-color: white;
    -webkit-transform: translate(-50%, -50%) scale(1);
       -moz-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
         -o-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    z-index: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon .fa{
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.social-icon .si-item:hover .si-icon{
    color: white;
}

.social-icon .si-item:hover .si-icon::after{
    opacity: 1;
}

.social-icon .si-item:hover .si-icon::before{
    -webkit-transform: translate(-50%, -50%) scale(0);
       -moz-transform: translate(-50%, -50%) scale(0);
        -ms-transform: translate(-50%, -50%) scale(0);
         -o-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
}

.social-icon .si-item:hover .si-icon::after{
    background-color: #f1f5f9;
    -webkit-transform: translate(-50%, -50%) scale(1);
       -moz-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
         -o-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
}

.social-icon .si-item.fb-item .si-icon::after{
    background-color: #2645a9;
    background-image: -webkit-linear-gradient(45deg, #2645a9, #4e78ff);
    background-image: -moz-linear-gradient(45deg, #2645a9, #4e78ff);
    background-image: -o-linear-gradient(45deg, #2645a9, #4e78ff);
    background-image: linear-gradient(45deg, #2645a9, #4e78ff);
}

.social-icon .si-item.insta-item .si-icon::after{
    background-color: #dc2743;
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: -moz-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: -o-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

.social-icon .si-item.li-item .si-icon::after{
    background-color: #0077b5;
    background-image: -webkit-linear-gradient(45deg, #0077b5, #4e78ff);
    background-image: -moz-linear-gradient(45deg, #0077b5, #4e78ff);
    background-image: -o-linear-gradient(45deg, #0077b5, #4e78ff);
    background-image: linear-gradient(45deg, #0077b5, #4e78ff);
}

.social-icon .si-item.youtube-item .si-icon::after{
    background-color: #dd0021;
}

.social-icon .si-item.whatsapp-item .si-icon::after{
    background-color: #44be53;
    background-image: -webkit-linear-gradient(45deg, #09ad1d, #44be53);
    background-image: -moz-linear-gradient(45deg, #09ad1d, #44be53);
    background-image: -o-linear-gradient(45deg, #09ad1d, #44be53);
    background-image: linear-gradient(45deg, #09ad1d, #44be53);
}

/* .social-icon .si-item.copy-item .si-icon::after,
.social-share-icon .ssi-item.copy-item .ssi-icon{
    background-color: var(--color5);
    background-image: linear-gradient(-45deg, var(--color5), var(--color6), var(--color7), var(--color8), var(--color9));
} */

/* --- Social Share Icon & Social Icons End --- */



/* --- offer-popupbx --- */
.offer-popupbx{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.164);
    backdrop-filter: blur(10px);
    z-index: 1024;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out
}
.offer-popupbx::-webkit-scrollbar{
    display: none;
}

.offer-popupbx.active{
    opacity: 1;
    visibility: visible;
}

.offer-popupbx-inner{
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 650px;
    padding: 15px;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s 0.5s ease-in-out
}

.offer-popupbx.active .offer-popupbx-inner{
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}

.offer-popupbx img{
    width: 100%;
    border-radius: 20px;
}

.offer-popupbx .overlayer{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out
}

.offer-popupbx .overlayer.active{
    opacity: 1;
    visibility: visible;
}

.closebutton{
    position: fixed;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.76);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: all 0.3s ease-in-out;
}

.closebutton svg{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.closebutton:hover{
    color: rgb(199, 0, 0);
    background-color: rgb(255, 223, 223);
    transform: rotate(180deg);
}


/* --- offer-popupbx --- */


/* --- Header Top Strip Start --- */
.header-top-strip{
    text-align: center;
    padding: 10px 10px;
    background: #a03042;
    background-image: linear-gradient(45deg, #a03042, #dc8378);
}

.hts-inner-bx{
    position: relative;
    max-width: 1240px;
    width: 100%;
    margin: auto;
    color: white;
    padding: 0 120px;
    font-size: 14px;
}

.hts-inner-bx span{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 2px 10px;
    background-color: white;
    border-radius: 50px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

@media screen and (max-width:768px) {
    .hts-inner-bx{
        padding: 0;
    }
    
    .hts-inner-bx span{
        position: initial;
        right: inherit;
        top: inherit;
        transform: initial;
        margin-top: 5px;
        display: block;

    }
}
/* --- Header Top Strip End --- */


/* --- Breadcrumb Css Start --- */
.breadcrumb-main-bx{
    padding: 10px 20px;
    margin-bottom: 15px;
    background: white;
    border-bottom: 1px solid #e5e7eb;
}

/* 
.breadcrumb-main-bx.bmb-back-btn{
    padding-left: 60px;
}

.breadcrumb-back-icon{
    position: absolute;
    left: 10px;
    top: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--celeste-600);
    background-color: var(--celeste-100);
    transition: all 0.3s ease-in-out;
}

.breadcrumb-back-icon:hover{
    color: var(--celeste);
}

.breadcrumb-back-icon svg{
    position: relative;
    left: -2px;
}

.breadcrumb-heading-bx{
    font-size: 16px;
    color: var(--celeste);
    font-weight: 500;
    white-space: nowrap;
}

.breadcrumb-heading-bx svg{
    width: 20px;
    height: 20px;
    position: relative;
    top: -1px;
    margin-right: 5px;
} 
*/


.breadcrumb-bx{   
    display: -webkit-inline-box;   
    display: -webkit-inline-flex;   
    display: -moz-inline-box;   
    display: -ms-inline-flexbox;   
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
    width: 100%;
}

.breadcrumb-bx .breadcrumb-link{
    font-size: 16px;
    font-weight: 500;
    margin-right: 5px;
    color: rgb(55, 65, 81);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    font-weight: 500;
    color: rgb(55, 65, 81);
    opacity: 0.5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link:hover{
    color: var(--electricblue);
    cursor: pointer;
}
/* --- Breadcrumb Css End --- */